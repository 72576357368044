<template>
  <section>
    <b-card>
      <validation-observer ref="userProfile">
        <b-row class="mb-3">
          <b-col cols="12">
            <b-button variant="primary" @click="$router.go(-1)">Indietro</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h3>Dati Personali</h3>
          </b-col>

          <b-col md="4">
            <b-form-group label="Nome">
              <validation-provider #default="{ errors }" name="Nome" rules="required">
                <b-form-input disabled v-model="user.user_name" :state="errors.length > 0 ? false : null">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Cognome">
              <validation-provider #default="{ errors }" name="Cognome" rules="required">
                <b-form-input v-model="user.user_surname" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Email">
              <validation-provider #default="{ errors }" name="Email" rules="required">
                <b-form-input v-model="user.user_email" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Ragione Sociale">
              <validation-provider #default="{ errors }" name="Ragione Sociale" rules="required">
                <b-form-input v-model="user.company_name" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Partita IVA">
              <validation-provider #default="{ errors }" name="Partita IVA" rules="required">
                <b-form-input v-model="user.user_vatnumber" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col md="12">
            <h3>Indirizzo (Sede Legale)</h3>
          </b-col>

          <b-col md="4">
            <b-form-group label="Indirizzo (sede legale)">
              <validation-provider #default="{ errors }" name="Indirizzo (Sede Legale)" rules="required">
                <b-form-input v-model="user.user_address_headquarter" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="CAP (sede legale)">
              <validation-provider #default="{ errors }" name="CAP (Sede Legale)" rules="required">
                <b-form-input v-model="user.user_zipcode_headquarter" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Città (sede legale)">
              <validation-provider #default="{ errors }" name="Città (Sede Legale)" rules="required">
                <b-form-input v-model="user.user_city_headquarter" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Provincia (sede legale)">
              <validation-provider #default="{ errors }" name="Provincia (Sede Legale)" rules="required">
                <b-form-input v-model="user.user_province_headquarter" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col md="12">
            <h3>Indirizzo (Sede Operativa)</h3>
          </b-col>

          <b-col md="4">
            <b-form-group label="Indirizzo (sede operativa)">
              <validation-provider #default="{ errors }" name="Indirizzo (Sede Operativa)" rules="required">
                <b-form-input v-model="user.user_address_operationoffice" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="CAP (sede operativa)">
              <validation-provider #default="{ errors }" name="CAP (Sede Operativa)" rules="required">
                <b-form-input v-model="user.user_zipcode_operationoffice" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Città (sede operativa)">
              <validation-provider #default="{ errors }" name="Città (Sede Operativa)" rules="required">
                <b-form-input v-model="user.user_city_operationoffice" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Provincia (sede operativa)">
              <validation-provider #default="{ errors }" name="Provincia (Sede Operativa)" rules="required">
                <b-form-input v-model="user.user_province_operationoffice" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col md="12">
            <h3>Dati Fatturazione</h3>
          </b-col>

          <b-col md="4">
            <b-form-group label="SDI">
              <validation-provider #default="{ errors }" name="SDI" rules="required">
                <b-form-input v-model="user.user_sdi" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="PEC">
              <validation-provider #default="{ errors }" name="PEC" rules="required">
                <b-form-input v-model="user.user_pec" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Codice Ateco">
              <validation-provider #default="{ errors }" name="Codice Ateco" rules="required">
                <b-form-input v-model="user.user_ateco" disabled></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <form>
          <b-row>
            <b-col md="12">
              <h3>Crediti</h3>
            </b-col>

            <b-col md="4">
              <b-form-group label="Crediti Progetti Installatore">
                <validation-provider #default="{ errors }" name="bought_credit_new" rules="required">
                  <b-form-input type="number" v-model="user.bought_credit_new"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Crediti Progetti Manutentore">
                <validation-provider #default="{ errors }" name="bought_credit_maintenance" rules="required">
                  <b-form-input type="number" v-model="user.bought_credit_maintenance"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Data scadenza crediti">
                <validation-provider #default="{ errors }" name="data_end" rules="required">
                  <b-form-input type="date" v-model="user.data_end"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>

            <b-col md="4">
              <b-form-group>
                <b-button :class="'submit-button'" type="submit" v-on:click.prevent="saveCredits()">Modifica
                  crediti</b-button>
              </b-form-group>
            </b-col>

          </b-row>
        </form>
      </validation-observer>
    </b-card>
  </section>
</template>

<style>
@media screen and (min-width: 768px) {
  .submit-button {
    margin-top: 25px;
  }

}
</style>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import { required, email } from "@validations";
import { Requests } from "@/api/requests.js";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BAvatar,
    BButton,
    BCard,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BModal,
  },

  data: () => ({
    user: {},

    //Validation Rules

    email,
    required,
  }),

  created() {
    this.getAccountDetails();
    localize("it");
  },

  methods: {
    async getAccountDetails() {
      let data = new FormData();

      data.append("id_user", this.$route.params.id);

      try {
        const response = await Requests.getAccountDetails(data);
        this.user = response.account_details.payload;
      } catch (err) {
        console.log(err);
      }
    },
    async saveCredits() {
      let data = new FormData();
      data.append('id_user', this.user.id_user);
      data.append('bought_credit_new', this.user.bought_credit_new);
      data.append('bought_credit_maintenance', this.user.bought_credit_maintenance);
      data.append('data_end', this.user.data_end);
      try {
        const response = await Requests.updateCredits(data);
        if (response.credit_update.success == 'true') {
          this.$swal('OK', 'Crediti aggiornati', 'success');
        }
      } catch (err) {
        console.log(err);
      }
    }
  },
};
</script>
