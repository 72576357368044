var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',[_c('validation-observer',{ref:"userProfile"},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Indietro")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Dati Personali")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Nome"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false : null},model:{value:(_vm.user.user_name),callback:function ($$v) {_vm.$set(_vm.user, "user_name", $$v)},expression:"user.user_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Cognome"}},[_c('validation-provider',{attrs:{"name":"Cognome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_surname),callback:function ($$v) {_vm.$set(_vm.user, "user_surname", $$v)},expression:"user.user_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_email),callback:function ($$v) {_vm.$set(_vm.user, "user_email", $$v)},expression:"user.user_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Ragione Sociale"}},[_c('validation-provider',{attrs:{"name":"Ragione Sociale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.company_name),callback:function ($$v) {_vm.$set(_vm.user, "company_name", $$v)},expression:"user.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Partita IVA"}},[_c('validation-provider',{attrs:{"name":"Partita IVA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_vatnumber),callback:function ($$v) {_vm.$set(_vm.user, "user_vatnumber", $$v)},expression:"user.user_vatnumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Indirizzo (Sede Legale)")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Indirizzo (sede legale)"}},[_c('validation-provider',{attrs:{"name":"Indirizzo (Sede Legale)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_address_headquarter),callback:function ($$v) {_vm.$set(_vm.user, "user_address_headquarter", $$v)},expression:"user.user_address_headquarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"CAP (sede legale)"}},[_c('validation-provider',{attrs:{"name":"CAP (Sede Legale)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_zipcode_headquarter),callback:function ($$v) {_vm.$set(_vm.user, "user_zipcode_headquarter", $$v)},expression:"user.user_zipcode_headquarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Città (sede legale)"}},[_c('validation-provider',{attrs:{"name":"Città (Sede Legale)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_city_headquarter),callback:function ($$v) {_vm.$set(_vm.user, "user_city_headquarter", $$v)},expression:"user.user_city_headquarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Provincia (sede legale)"}},[_c('validation-provider',{attrs:{"name":"Provincia (Sede Legale)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_province_headquarter),callback:function ($$v) {_vm.$set(_vm.user, "user_province_headquarter", $$v)},expression:"user.user_province_headquarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Indirizzo (Sede Operativa)")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Indirizzo (sede operativa)"}},[_c('validation-provider',{attrs:{"name":"Indirizzo (Sede Operativa)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_address_operationoffice),callback:function ($$v) {_vm.$set(_vm.user, "user_address_operationoffice", $$v)},expression:"user.user_address_operationoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"CAP (sede operativa)"}},[_c('validation-provider',{attrs:{"name":"CAP (Sede Operativa)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_zipcode_operationoffice),callback:function ($$v) {_vm.$set(_vm.user, "user_zipcode_operationoffice", $$v)},expression:"user.user_zipcode_operationoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Città (sede operativa)"}},[_c('validation-provider',{attrs:{"name":"Città (Sede Operativa)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_city_operationoffice),callback:function ($$v) {_vm.$set(_vm.user, "user_city_operationoffice", $$v)},expression:"user.user_city_operationoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Provincia (sede operativa)"}},[_c('validation-provider',{attrs:{"name":"Provincia (Sede Operativa)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_province_operationoffice),callback:function ($$v) {_vm.$set(_vm.user, "user_province_operationoffice", $$v)},expression:"user.user_province_operationoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Dati Fatturazione")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"SDI"}},[_c('validation-provider',{attrs:{"name":"SDI","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_sdi),callback:function ($$v) {_vm.$set(_vm.user, "user_sdi", $$v)},expression:"user.user_sdi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"PEC"}},[_c('validation-provider',{attrs:{"name":"PEC","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_pec),callback:function ($$v) {_vm.$set(_vm.user, "user_pec", $$v)},expression:"user.user_pec"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Codice Ateco"}},[_c('validation-provider',{attrs:{"name":"Codice Ateco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_ateco),callback:function ($$v) {_vm.$set(_vm.user, "user_ateco", $$v)},expression:"user.user_ateco"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Crediti")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Crediti Progetti Installatore"}},[_c('validation-provider',{attrs:{"name":"bought_credit_new","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.user.bought_credit_new),callback:function ($$v) {_vm.$set(_vm.user, "bought_credit_new", $$v)},expression:"user.bought_credit_new"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Crediti Progetti Manutentore"}},[_c('validation-provider',{attrs:{"name":"bought_credit_maintenance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.user.bought_credit_maintenance),callback:function ($$v) {_vm.$set(_vm.user, "bought_credit_maintenance", $$v)},expression:"user.bought_credit_maintenance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Data scadenza crediti"}},[_c('validation-provider',{attrs:{"name":"data_end","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date"},model:{value:(_vm.user.data_end),callback:function ($$v) {_vm.$set(_vm.user, "data_end", $$v)},expression:"user.data_end"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-button',{class:'submit-button',attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.saveCredits()}}},[_vm._v("Modifica crediti")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }